body{
    background-color: cadetblue;
}

h1{
    font-size: 38px;
}

input[type=text] {
    width: 30%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-radius: 15px;
    background-color: rgb(255, 249, 249);
    color: rgb(10, 10, 10);
    font-family: Arial, Helvetica, sans-serif;
}

input[type=password] {
    width: 30%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-radius: 15px;
    background-color: rgb(255, 249, 249);
    color: rgb(10, 10, 10);
    font-family: Arial, Helvetica, sans-serif;
}

input[type=submit] {
    background-color: #ddd;
    border: none;
    color: black;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    /* cursor: pointer; */
    border-radius: 16px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 22px;
}

select {
    width: 20%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    color: rgb(10, 10, 10);
    font-family: Arial, Helvetica, sans-serif;
}


.container{
    background-color: #E84A34;
    border-style: hidden;
    border-radius: 15px;
    padding:18px;
    text-align: center;
    justify-content: space-between;
    box-shadow: 0 8px 6px -6px black;
}

@media screen and (max-width:900px){

    .main-contianer{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center; 
        
    }
}